import moment from "moment";
import { search } from "./resource.service";
import { DISABLE_API_LOADER, DISABLE_PAYMENT } from "../config";
import { CONFIRMED_STATUSES, PENDING_STATUSES } from "../constants/reservationStatus.constants";
import { processEventDates } from "./util.service";
import { resourceTypes } from "../redux/types";

const DEFAULT_API_LOADER_TIME = 500;

/** 
 *  @function fnBookedDates
  * @description Process the usage of the selected resource 
  * @param {object} booking
  * @return {object} dates that are booked and dates that are available 
*/
export const fnBookedDates = (booking) => {

    return new Promise((resolve) => {
        /*
            dates -> contains only booked dates
            bookedDates -> Object that stores dates, whether past, present or future, which are truly booked 
            bookableDatesObj -> contains all the days along with the properties like bookability and css classes like clickability, availability
            rentalDaysArr -> contains the days on which resource can be booked
        */
        let dates = {};
        let bookedDates = {};
        let bookableDatesObj = [];
        let rentalDaysArr = [];
        let oddUnits = [];
        let evenUnits = [];
        if (booking?.selectedResource?.properties?.rental_starting_days) {
            rentalDaysArr = booking?.selectedResource?.properties?.rental_starting_days?.split(",")?.map(current => {
                if (booking?.selectedResource?.unit_names) {
                    const daysDistributor = unitManager(booking?.selectedResource?.unit_names);
                    if (daysDistributor?.oddUnits) oddUnits = [...daysDistributor?.oddUnits];
                    if (daysDistributor?.evenUnits) evenUnits = [...daysDistributor?.evenUnits];
                }
                return parseInt(current);
            });
        }
        const usageObj = booking?.selectedResource?.usage || {};
        let maxBookableDate = moment().add(parseInt(booking?.selectedResource?.max_days_to_rental) - 1, 'd').format("YYYY-MM-DD");
        if (usageObj) {
            for (let x in usageObj) {
                /*
                    x -> month
                    x2 -> date
                    x3 -> hour
                    x4 -> minutes
                */

                if (!dates?.hasOwnProperty(x)) { dates[x] = []; }
                if (!bookedDates?.hasOwnProperty(x)) { bookedDates[x] = []; }
                for (let x2 in usageObj[x]) {
                    //If usageObj[x][x2] is not an object and instead it contains a number, then if that number is same as resource quantity, then resource is booked for that date
                    if ((booking?.selectedResource?.time_unit <= 60) && (typeof (usageObj[x][x2]) != "object") && (parseInt(booking?.selectedResource?.quantity) - parseInt(usageObj[x][x2]) < 1)) {
                        // booked = true;
                        // isTrulyBooked = true;
                        dates[x].push(x2);
                        bookedDates[x].push(x2);
                        continue;
                    }
                    let booked = true; // this variable marks dates that are past as booked dates, even if they are not completely booked
                    let isTrulyBooked = true; // this variable marks only those dates as booked that are fully used up, be it in past, present or future
                    let startingHours = parseInt(booking?.selectedResource?.start_hour);
                    let endingHours = parseInt(booking?.selectedResource?.end_hour);

                    if (booking?.selectedResource?.time_unit === 60) {
                        let remainingHours;
                        if (moment(x)?.set('date', x2)?.format("YYYY-MM-DD") === moment()?.format("YYYY-MM-DD")) {
                            remainingHours = parseInt(booking?.selectedResource?.end_hour) - parseInt(moment()?.format("HH"));
                        } else {
                            remainingHours = parseInt(booking?.selectedResource?.end_hour) - parseInt(booking?.selectedResource?.start_hour);
                        }
                        for (let x3 in usageObj[x][x2]) {
                            remainingHours--;
                            let formedDate = moment(x)?.set('date', x2)?.set('hour', x3);
                            if (x3 >= startingHours && x3 < endingHours) {
                                if (booking?.selectedResource?.quantity && (parseInt(booking?.selectedResource?.quantity) - usageObj[x][x2][x3]) > 0) {
                                    isTrulyBooked = false;
                                    if (formedDate?.isAfter(moment())) {
                                        booked = false;
                                        break;
                                    }
                                    // break;
                                }
                            }
                        }

                        if (remainingHours > 0) {
                            booked = false;
                            isTrulyBooked = false;
                        }
                    } else if (booking?.selectedResource?.time_unit < 60) {
                        let remainingHours;
                        if (moment(x)?.set('date', x2)?.format("YYYY-MM-DD") === moment()?.format("YYYY-MM-DD")) {
                            remainingHours = parseInt(booking?.selectedResource?.end_hour) - parseInt(moment()?.format("HH"));
                            remainingHours = remainingHours - (parseInt(moment()?.format("mm")) > 30 ? 1 : 0);
                        } else {
                            remainingHours = parseInt(booking?.selectedResource?.end_hour) - parseInt(booking?.selectedResource?.start_hour);
                        }
                        for (let x3 in usageObj[x][x2]) {
                            remainingHours--;
                            if (x3 >= startingHours && x3 < endingHours) {
                                for (let x4 in usageObj[x][x2][x3]) {
                                    let formedDate = moment(x)?.set('date', x2)?.set('hour', x3)?.set('minute', x4);
                                    if (booking?.selectedResource?.quantity && (parseInt(booking?.selectedResource?.quantity) - usageObj[x][x2][x3][x4] > 0)) {
                                        isTrulyBooked = false;
                                        if (formedDate?.isAfter(moment())) {
                                            booked = false;
                                            break;
                                        }
                                    }
                                }
                            }

                        }
                        if (remainingHours > 0) {
                            booked = false;
                            isTrulyBooked = false;
                        }
                    } else {
                        let formedDate = moment(x)?.set('date', x2);
                        if (booking?.selectedResource?.quantity && (parseInt(booking?.selectedResource?.quantity) - usageObj[x][x2]) > 0) {
                            isTrulyBooked = false;
                            if (formedDate?.format("YYYY-MM-DD") >= moment()?.format("YYYY-MM-DD")) {
                                booked = false;
                            }
                        }
                    }
                    if (booked) {
                        dates[x].push(x2);
                    }
                    if (isTrulyBooked) {
                        bookedDates[x].push(x2);
                    }
                }
            }

            //sets parameters for bookable dates
            const setBookableDateProps = (currentDayObj) => {
                return {
                    ...currentDayObj,
                    isAvailable: " available_date",
                    isClickable: true,
                    isBookable: true
                }
            }

            let usageArr = Object?.keys(usageObj);
            let resourceID = booking?.selectedResource?.unitUsage ? Object?.keys(booking?.selectedResource?.unitUsage)?.[0]?.split("/")?.[0] : "";

            /**
             * a flag that is used to represent the following information:
             *      - true: a valid rental day has been found, we don't need to check a substitute date
             *      - false: the valid rental day is on vacation, therefore we need to find a substitute date that's needs to be made available
             */
            let previousDateFlag = true;
            // for monitoring the valid but booked date
            let previousDate = "";

            //check for current date resource time expired
            const startQuarters = booking?.selectedResource?.start_quarters || 1;
            const startMinutes = getStartMinutesAndArray(startQuarters)?.startMinutes

            const startTime = moment(booking?.selectedResource?.start_hour || '00', "hh").add(startMinutes, 'm')
            let endTime = moment(booking?.selectedResource?.end_hour || '23', "hh");
            const totalTimeDiff = endTime.diff(startTime); //in milliseconds
            const totalTimeSlots = Math.floor(totalTimeDiff / (booking?.selectedResource?.min_rental_time * 3600 * 1000));
            const lastPossibleStartTime = startTime.clone().add((totalTimeSlots - 1) * booking?.selectedResource?.min_rental_time * 60, 'minutes');

            //   let endTimeStamp = startTime?.subtract(booking?.selectedResource?.min_rental_time, 'hours');

            for (let yearMonth of usageArr) {
                // finding months
                const sepYearMonth = yearMonth?.split("-").map(item => parseInt(item));
                let daysInMonth = moment(yearMonth, "YYYY-MM").daysInMonth();
                for (let day = 1; day <= daysInMonth; day++) {
                    const date = moment().year(parseInt(sepYearMonth[0])).month(parseInt(sepYearMonth[1] - 1)).date(day).format("YYYY-MM-DD");

                    const isCurrentDayExpired = booking?.selectedResource?.time_unit <= 60 && date === moment()?.format("YYYY-MM-DD") && moment()?.isAfter(lastPossibleStartTime);

                    if (moment(date).isAfter(maxBookableDate) || isCurrentDayExpired) {
                        if (!dates[yearMonth]?.includes(day?.toString())) {
                            dates[yearMonth]?.push(day?.toString());
                        }
                    }
                    let booked = bookedDates[yearMonth]?.includes(day.toString()) ? true : false;

                    let currentDate = moment(yearMonth)?.set('date', day);
                    //default state of bookable days object
                    let currentDayObj = {
                        date: currentDate?.format("YYYY-MM-DD"),
                        isAvailable: "",
                        isClickable: false,
                        isBookable: false,
                    }

                    //For normal weekly booking where all dates are available on the basis of their usage
                    if (rentalDaysArr.length === 0) {
                        currentDayObj = setBookableDateProps(currentDayObj);
                    }
                    /**
                     * For selective weekly booking -> in case the current date is a valid rental date and its available for booking
                     * making previousDateFlag to true so that for the current week, since we have found a valid rental day, we don't need to carry forward to search a new date
                     * by default, making the object corresponding to current date as truthy, i.e., it will be bookable, clickable and available, which will be used to add corresponding styles on the calendar screen
                     * selectableUnit function will check whether there's a unit available for the current date
                     *      - if available, then keep the bookable object truthy
                     *      - if not available, revert it to be falsy, i.e, the date will no longer be bookable, clickable and available
                    */
                    else if (rentalDaysArr?.find(item => item === currentDate.day() && !booked)) {
                        previousDateFlag = true;
                        currentDayObj = setBookableDateProps(currentDayObj);
                        let selectedUnit = selectableUnit(
                            resourceID,
                            booking?.selectedResource?.unit_names,
                            booking?.selectedResource?.unitUsage,
                            currentDate,
                            oddUnits,
                            evenUnits,
                            currentDayObj);
                        if (Object.keys(selectedUnit)?.length > 0) {
                            currentDayObj = selectedUnit;
                        }
                    }
                    /**
                     * For selective weekly booking -> in case the current date is a valid rental date but it's completely booked
                     * setting the previousDateFlag to false, as we need to find the next available date for its validity
                     * storing the currentDate to previousDate variable to keep a track of the immediately previous date that was valid but booked, which is needed in further iterations
                     */
                    else if (rentalDaysArr?.find(item => item === currentDate.day() && booked)) {
                        previousDateFlag = false;
                        previousDate = currentDate;
                    }
                    /**
                     * For selective weekly booking -> in case the current date is a not a valid rental date but it's available and previous rentalDate was not available, i.e. previousDateFlag is false
                     * set prviousDateFlag to true as we have found a valid rental day for check-in
                     * by default, making the object corresponding to current date as truthy, i.e., it will be bookable, clickable and available, which will be used to add corresponding styles on the calendar screen
                     * selectableUnit function will check whether there's a unit available for the current date
                     *      - if available, then keep the bookable object truthy
                     *      - if not available, revert it to be falsy, i.e, the date will no longer be bookable, clickable and available
                     */
                    else if (!booked && !previousDateFlag) {
                        currentDayObj = setBookableDateProps(currentDayObj);
                        previousDateFlag = true;
                        let selectedUnit = selectableUnit(
                            resourceID,
                            booking?.selectedResource?.unit_names,
                            booking?.selectedResource?.unitUsage,
                            currentDate,
                            oddUnits,
                            evenUnits,
                            currentDayObj,
                            previousDate);
                        if (Object.keys(selectedUnit)?.length > 0) {
                            currentDayObj = selectedUnit;
                        }
                        previousDate = "";
                    }
                    // for disabling the current day being set as a selected date
                    if (currentDate?.format("YYYY-MM-DD") === moment()?.format("YYYY-MM-DD") && !booked) {
                        if (rentalDaysArr.length !== 0 && !rentalDaysArr?.find(item => item === currentDate.day())) {
                            dates[yearMonth]?.push(day?.toString());
                        }
                    }


                    bookableDatesObj.push(currentDayObj);

                }
                dates[yearMonth]?.sort((a, b) => {
                    return parseInt(a) < parseInt(b);
                });
            }
        }
        resolve({ dates, bookableDatesObj });
    })
}

/**
 * @function checkInDate
 * @description Generates the available checkIn dates
 * @param {moment} date input date
 * @param {number} scrollMonths number of months for which dates needs to be generated
 * @param {string} rentalDays  days on which the resource can be booked
 * @param {object} bookableDays  available days
 * @returns {Array.<object> } a list of bookable check-in days based on rentalDays(if present) and date
 */
export const checkInDate = (date, scrollMonths = 3, rentalDays = "", unitNames = "", bookableDays = []) => {
    return new Promise((resolve, reject) => {
        try {
            let list = [];
            let date = moment();
            while (date.month() !== moment().add(scrollMonths, 'M').month()) {
                if (rentalDays && unitNames) {
                    if (bookableDays?.find(item => item?.date === date?.format("YYYY-MM-DD") && (item?.isClickable && item?.isBookable))) {
                        list.push({ id: date.format('YYYY-MM-DD'), label: date.format('D MMMM') });
                    }
                } else {
                    list.push({ id: date.format('YYYY-MM-DD'), label: date.format('D MMMM') });
                }

                date.add(1, 'd');
            }

            resolve(list);
        } catch (error) {
            reject(error);
        }
    })
}

/**
 * @function checkOutDate
 * @description Generates the available checkOut dates
 * @param {object} booking selected resource object
 * @param {number} scrollMonths number of months for which dates needs to be generated
 * @returns {Array.<object> } a list of bookable check-out days 
 */
export const checkOutDate = (booking, scrollMonths = 3) => {
    return new Promise((resolve, reject) => {
        try {
            let list = [];
            // finding the last bookable month
            let maxBookableMonth = moment().add(scrollMonths - 1, "M").format("YYYY-MM");
            let oddDaysArr = [1, 2, 3];
            if (booking?.reservationSlot?.date) {
                const startDate = moment(booking?.reservationSlot?.date);
                let initialDaysToAdd = 6;
                if (booking?.selectedResource?.properties?.rental_starting_days) {
                    // for booking starting between Monday to Wednesday
                    if (oddDaysArr?.includes(startDate?.day())) {
                        initialDaysToAdd = 7 - startDate?.day();
                    } else {
                        // for booking starting from Thursday or afterwords
                        initialDaysToAdd = 10 - startDate?.day();
                    }
                }
                startDate.add(initialDaysToAdd, 'd');
                // while (startDate.month() !== month + 3) {
                for (let i = 0; i <= scrollMonths; i++) {
                    // startDate.add(7, 'd');
                    let currentMonth = moment(startDate).format("YYYY-MM");
                    if (moment(currentMonth)?.isSameOrBefore(maxBookableMonth)) {
                        list.push({ id: startDate.format('YYYY-MM-DD'), label: startDate.format('D MMMM') });
                        startDate.add(7, 'd');
                    } else {
                        break;
                    }
                }
                resolve(list);
            } else {
                reject();
            }
        } catch (error) {
            reject(error);
        }
    })
}
/*
    removed commented function
    functionName: getUnit
    last commitId:9f1a37b60eb414d1f402748cbd736798532ecb4a
*/

/**
 * @function getUnitArr
 * @description a function that returns the possible units that can be assigned to the user based on a given check-in and check-out date
 *              - based on the check-in date, it determines the type of unit: odd/even and stores it in isOddResource variable
 *              - if remainingUnits is non-empty, it iterates through only those units contained in remainingUnits, otherwise iterates through all possible units for the resource
 *              - if unit usage is free for the entire duration from check-in to check-out date, that unit is pushed in the unit array
 *              - after all iterations are completed, it returns all available units for the given reservation period
 *              - if no unit is available, then it returns an empty array
 * @param {object} param0 an object that contains:
 *                        - booking (object): contains booking details for the corresponding resource
 *                        - id (string): the id of the resource
 *                        - currentEndDate (string): check-out date corresponding to a user-selected check-in date
 *                        - remainingUnits (array): an array of strings that represent units of the resource
 * @returns {array} returns an array of available units for the given reservation period. Returns empty array if no unit is available for booking
 */
export const getUnitArr = ({ booking, id, currentEndDate, remainingUnits = [] }) => {
    let oddDays = [1, 2, 3];
    const isOddResource = oddDays?.includes(moment(booking?.reservationSlot?.date)?.day()) ? true : false;
    const units = remainingUnits?.length > 0 ? remainingUnits : booking?.selectedResource?.unit_names?.split(",")?.map(current => parseInt(current));
    let unitArr = [];
    for (let unit of units) {
        if (((isOddResource && unit % 2 !== 0) || (!isOddResource && unit % 2 === 0))) {
            // incase unit usage is blank
            if (!booking?.selectedResource?.unitUsage[`${id}/${unit}`]) {
                // return unit;
                unitArr.push(unit);
                continue;
            }
            let flag = false;
            let startDate = moment(booking?.reservationSlot?.date);
            const endDate = moment(currentEndDate);
            while (startDate?.isSameOrBefore(endDate)) {
                let currentUsage = booking?.selectedResource?.unitUsage[`${id}/${unit}`]?.[startDate?.year()]?.[startDate?.month() + 1]?.[startDate?.date()] || 0;
                if (currentUsage === 1) {
                    flag = true;
                    break;
                }
                else {
                    startDate?.add(1, 'd');
                }
            }
            if (!flag) {
                // return unit;
                unitArr.push(unit);
            }

        }
    }
    return unitArr;
}

/**
 * @function selectableUnit
 * @description if it is a selective day resource, then based on the current date, we find the unit type (odd or even) that may be assigned to the user on this date:
 *                  - if all appropriate unit types are free on the current date, then we return an empty object so that the current date is made available for the user to select
 *                  - if all unit types for the currentDate are occupied, then we return an object that marks the currentDate as unavailable
 * @param {string} resourceID selected resource ID
 * @param {string} unitNames 
 * @param {object} unitUsage 
 * @param {object} currentDate 
 * @param {Array.<number>} oddUnits 
 * @param {Array.<number>} evenUnits 
 * @param {object} currentDayObj contains the classes and its bookability
 * @param {object} previousDate previous actual bookable day based on rental_days_array property
 * @returns {object}
 */
const selectableUnit = (resourceID = "", unitNames = "", unitUsage = {}, currentDate = moment(), oddUnits = [], evenUnits = [], currentDayObj = {}, previousDate = "") => {
    if (resourceID && unitNames) {
        let unitArr = (previousDate !== "" ? previousDate?.day() : currentDate?.day()) === 1 ? [...oddUnits] : [...evenUnits];
        let flag = false;
        for (let i of unitArr) {
            let usageValue = unitUsage[`${resourceID}/${i}`]?.[currentDate?.year()]?.[currentDate?.month() + 1]?.[currentDate?.date()] || 0;
            if (usageValue !== 1) {
                flag = true;
                break;
            }
        }
        if (flag === false) {
            return {
                ...currentDayObj,
                isClickable: false,
                isBookable: false,
                isAvailable: "",
            }
        }
    }
    return {};
}
/** 
 * @function unitManager
 * @description generating the odd and even unit names array out of string
 * @param {string} units string containing all the unit numbers 
 * @returns {object} returns the object containing event and odd units array
 */
const unitManager = (units = "") => {
    let unitObj = {
        oddUnits: [],
        evenUnits: []
    }
    // will return the unit_names in string
    unitObj.evenUnits = units?.split(",")?.filter(current => parseInt(current) % 2 === 0);
    unitObj.oddUnits = units?.split(",")?.filter(current => parseInt(current) % 2 !== 0);
    return unitObj;
}

export const fixTime = (time) => {
    let hours = parseInt(time.split(':')[0]);
    let minutes = time.split(':')[1];
    if (minutes === '59') {
        minutes = '00';
        hours = hours + 1;
    } else if (minutes === '01') {
        minutes = '00';
    } else if (minutes === '31' || minutes === '29') {
        minutes = '30';
    }
    return `${hours < 10 ? `0${hours}` : hours}:${minutes}`
}

/**
 * @function getActiveBookings
 * @description API call to fetch the list of active bookings for a user, against a meeting place
 * @param {object} param0 Object that contains the payload required to find the list of active bookings
 * @param {*} dispatch Function to send actions into the redux store
 * @returns {Promise<object>} For successful query, returns the response object that contains the list of active bookings
 */
export const getActiveBookings = ({ userId, startDate, endDate }, dispatch = () => { }) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        const startDateM = moment(startDate);
        const endDateM = moment(endDate);
        const startTimeParam = startDateM?.format("YYYY-MM-DD[T]HH:mm");
        const endTimeParam = endDateM?.format("YYYY-MM-DD[T]HH:mm");
        search({ method: "list_reservations", detail_level: 135, requiredStatus: 1, startTime: startTimeParam, endTime: endTimeParam, user_id: userId })
            .then((reservations) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(reservations);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
};

/**
 * @function getOldBookings
 * @description API call to fetch the list of old bookings for a user, against a meeting place
 * @param {object} param0 Object that contains the payload required to find the list of old bookings
 * @param {*} dispatch Function to send actions into the redux store
 * @returns {Promise<object>} For successful query, returns the response object that contains the list of old bookings
 */
export const getOldBookings = ({ userId, startDate }, dispatch = () => { }) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        const startDateM = moment(startDate);
        const endDateM = moment();
        const startTimeParam = startDateM?.format("YYYY-MM-DD[T]HH:mm");
        const endTimeParam = endDateM?.format("YYYY-MM-DD[T]HH:mm");
        search({ method: "list_reservations", detail_level: 133, startTime: startTimeParam, endTime: endTimeParam, user_id: userId })
            .then((reservations) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(reservations);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
};

/**
 * @function setReservationStatus
 * @description Maps a Planyo reservation status code to a human readble status string
 * @param {string} status The status code returned by Planyo for a reservation
 * @param {string} checkOutTime The checkout time returned by Planyo for a reservation
 * @returns {string} The mapped status 'label' for a respective status code. Can only take 'Pending'/'Confirmed'/'Expired' values.
 */
export const setReservationStatus = (status, checkOutTime) => {
    const currTime = moment();
    const bookingEndTime = moment(checkOutTime);
    currTime?.isBefore(bookingEndTime);
    let label;
    if (currTime?.isBefore(bookingEndTime)) {
        if (PENDING_STATUSES.includes(status)) {
            label = 'Pending';
        } else if (CONFIRMED_STATUSES.includes(status)) {
            label = 'Confirmed';
        } else {
            label = 'Expired';
        }
    } else {
        label = 'Expired';
    }
    return label;
}

/**
 * @function getStartMinutesAndArray
 * @description function to get startMinutes and minuteArray.
 * @param {number} startQuarters code represetation for valid quarters
 * @returns {object} object contains the valid startMinutes which will be added to start_hour for first time slot and minuteArray contaig all the valid minutes which will be added to an hour in order to obtain valid time slots
 */
export const getStartMinutesAndArray = (startQuarters) => {
    // quarters code mapping to corresponding minutes
    const quarters = {
        1: 0,    // :00
        8: 15,   // :15
        64: 30,  // :30
        512: 45  // :45
    };

    const minuteArray = [];
    for (const [key, value] of Object.entries(quarters)) {
        // using bitmaskig and binary operation to identify which quartercode is included
        if (startQuarters & parseInt(key)) {
            minuteArray.push(value);
        }
    }
    // sort the minute array to get all minutes in ascending order
    minuteArray.sort((a, b) => a - b);
    // starting minute is the first element of the sorted minute array
    const startMinutes = minuteArray[0];

    return { startMinutes, minuteArray };
}

export const getRentalPriceInfo = ({ resourceId, startDate, endDate, quantity }, dispatch = () => { }) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        // const startDateM = moment(startDate);
        // const endDateM = endDate ? moment(endDate) : moment();
        const startTimeParam = startDate?.format("YYYY-MM-DD[T]HH:mm");
        const endTimeParam = endDate?.format("YYYY-MM-DD[T]HH:mm");
        // search({ method: "get_rental_price", startTime: startTimeParam, endTime: endTimeParam, quantity, id: resourceId, headers: { Planyo: true } })
        search({ method: "get_rental_price", startTime: startTimeParam, endTime: endTimeParam, quantity, id: resourceId })
            .then((info) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(info);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
}

/**
 * @function getWaitTime
 * @description a function that calls the get_custom_property API to fetch the time duration in which the user has to wait before the booking is restored 
 * @param {string} siteId a string that contains the site id of the corresponding site type of a meeting place
 * @param {function} dispatch function to send action to the redux store
 * @returns 
 */
export const getWaitTime = (siteId, siteType, dispatch) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    return new Promise((resolve, reject) => {
        // search({ method: "get_custom_property", name: "hidden_unfinished_safe_period", type: "site", customPropertyId: siteId, siteId, headers: { Planyo: true } })
        search({ method: "get_custom_property", name: "hidden_unfinished_safe_period", type: "site", customPropertyId: siteId, siteId, category: siteType, getPropertyFor: "site" })
            .then((resp) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                resolve(resp);
            }).catch((err) => {
                if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, DEFAULT_API_LOADER_TIME); }
                reject(err);
            });
    })
}

/**
  * @function getBookingDuration
  * @param {object} reservationSlot a redux object that contains reservation info like check-in check-out date, time in, time out
  * @param {function} t translation function
  * @description function that calculates the duration (in days) between the check in and check out dates selected by the user
  * @returns {string} returns a string which shows the duration between check in and check out dates
  */
export const getBookingDuration = (reservationSlot, t) => {
    const startDate = moment(reservationSlot?.date);
    const endDate = moment(reservationSlot?.checkOutDate || reservationSlot?.date);
    // if (reservationSlot?.timeIn && reservationSlot?.timeOut) {
    //     const timeIn = reservationSlot?.timeIn?.split(':');
    //     const timeOut = reservationSlot?.timeOut?.split(':');
    //     startDate?.set({ 'hours': timeIn[0], 'minutes': timeIn[1] });
    //     endDate?.set({ 'hours': timeOut[0], 'minutes': timeOut[1] });
    // }
    let duration = (endDate?.diff(startDate, 'days', true) + 1) / 7;
    duration = Math.ceil(duration);
    return (duration + " " + (duration === 1 ? t("S_WEEK") : t("S_WEEKS")));
}

/**
 * @function findCancellationMode
 * @description function for finding the correct mode based on the following information:
 *              - in case the resource is not payment enabled then returned mode will be `cancel`
 *              - if the resource is payment enabled but the time difference between the start date and 
 *                current date is less than the refund- threshold value then mode will be `cancelAfterBufferPeriod`
 *              - if the resource is payment enabled and the time difference between the start date and current 
 *                date is greater than refund threshold then mode will be`cancelWithinBufferPeriod`
 * 
 * @returns {string} mode of cancellation
 */
export const findCancellationMode = (confirmationType, reservationSlot, refundPeriod) => {
    const isPaymentEnabled = !DISABLE_PAYMENT && (parseInt(confirmationType) === 6)
    if (!isPaymentEnabled) return 'cancel';
    else if (isPaymentEnabled && refundPeriod) {
        const timeDifference = moment.duration(moment(`${reservationSlot?.date} ${reservationSlot?.timeIn}`).diff(moment())).asHours()?.toFixed(2);
        if (timeDifference > parseFloat(refundPeriod)) {
            return 'cancelWithinBufferPeriod';
        } else {
            return 'cancelAfterBufferPeriod'
        }
    }
}
/**
 * @function createEventDates
 * @description function for creating an array of dates based on the given input
   * @example getUTCOffset("2*daily 2023-11-20 10:00") => ['2023-11-20 10:00','2023-11-21 10:00']; 
 * @param {string} eventDates starting date-time of the event along with the range of days. 
 * @returns {Array<string>} array of date-time on which the event will occur
 */
const createEventDates = (eventDates) => {
    let dateFormed = [];

    if (!eventDates?.includes("*")) {
        const dates = eventDates?.split(",");
        return dates;
    } else {
        const dateInfo = eventDates?.split(" ");
        const multiplier = dateInfo[0];
        const dateMoment = moment(dateInfo[3]);
        const timeStamp = dateInfo.length === 5 ? dateInfo[4] : '';
        let interval = 'day';

        if (eventDates?.includes("weekly")) {
            interval = 'week';
        } else if (eventDates?.includes("monthly")) {
            interval = 'month';
        }
        dateFormed[0] = `${dateMoment?.format("YYYY-MM-DD")} ${timeStamp}`?.trim();
        for (let index = 1; index < multiplier; index++) {
            dateMoment?.add(1, interval);
            dateFormed[index] = `${dateMoment?.format("YYYY-MM-DD")} ${timeStamp}`?.trim();
        }
        return dateFormed;
    }
}

/**
 * Sorts an array of date strings chronologically.
 * 
 * @param {string[]} datesArray - An array of date strings in the format "YYYY-MM-DD" or "YYYY-MM-DD HH:mm".
 * @returns {string[]} - An array of sorted date strings in chronological order.
 */
const sortDates = (datesArray) => {
    const sortedDates = datesArray.map(dateString => {
        if (dateString.includes(" ")) {
            return moment(dateString, "YYYY-MM-DD HH:mm");
        } else {
            return moment(dateString, "YYYY-MM-DD");
        }
    });

    sortedDates.sort((a, b) => a - b);

    return sortedDates.map(date => {
        if (date.format("HH:mm") === "00:00") {
            return date.format("YYYY-MM-DD");
        } else {
            return date.format("YYYY-MM-DD HH:mm");
        }
    });
}

/**
 * @function setSoldOutOrPastEvent
 * @description outer loop - iterating over the list of event resources;
 *              inner loop - iterating over the dates of occurrence of that event, and checking its availability against resourceUsage object
 * @param {object} param0 
 */
export const setSoldOutOrPastEvent = ({ resourceUsage, list, dispatch }) => {
    list?.map((item) => {
        const eventDateRegex = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{1,2},\s\d{4}\b/;
        let isExclusiveCase = eventDateRegex.test(item?.event_dates);
        let initList = isExclusiveCase ? item?.event_dates?.replace(',', '')?.split("#") : item?.event_dates?.split(',');
        item["event_dates"] = processEventDates(initList)?.join(',');

        // need a logic for converting different date format to a standard on which below logic can be executed
        let dates = createEventDates(item?.event_dates);
        dates = sortDates(dates);
        // if (!item?.event_dates?.includes("*")) {
        let isPastEvent = false;
        let isSoldOut = false;
        if (resourceUsage[item?.id]) {
            for (let date of dates) {
                const dateMoment = moment(date);
                // also check whether the event is past_event or not
                if (dateMoment?.isBefore(moment())) {
                    continue;
                } else {
                    const year = dateMoment?.year();
                    const monthNumber = dateMoment?.month() + 1; // Adding 1 because months are zero-indexed
                    const dayOfMonth = dateMoment?.date();
                    const hour = dateMoment?.hour();
                    const minute = dateMoment?.format('mm');
                    // debugger
                    const usagePath = [item?.id, year, monthNumber, dayOfMonth, hour, minute].filter(Boolean);
                    // reduce => callBack(accumulate,current),initialValue
                    const usage = usagePath.reduce((obj, prop, index) => {
                        const currentValue = obj?.[prop];
                        // backtracking values in case of undefined(probably on vacation), from minutes to month in reverese order
                        if (index >= 2 && !currentValue) {
                            if (typeof obj !== 'object' && obj === parseInt(item?.quantity)) {
                                return obj;
                            }
                        }
                        return currentValue;

                    }, resourceUsage);

                    if (usage && usage >= parseInt(item?.quantity)) {
                        isSoldOut = true;
                    } else {
                        // if encounter even a single date is free then mark it as not soldOut
                        isSoldOut = false;
                        break;
                    }
                }
            }
        } else {
            isPastEvent = true;
            if (moment(dates[dates.length - 1])?.isSameOrAfter(moment())) {
                isPastEvent = false;
            }
        }
        // update original resourceList
        item["isSoldOut"] = isSoldOut;
        item["isPastEvent"] = isPastEvent;
    })
    dispatch({ type: resourceTypes.RESOURCES, payload: list });
}